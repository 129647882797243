<template>
  <div>
    <b-container class="my-2">
      <h2 class="font-weight-bolder text-colorBlue pt-5 text-center">
        Hospitals and Institutes
      </h2>
      <b-row class="mt-3">
        <b-col
          sm="12"
          v-for="(hospital, index) in hospitals"
          :key="index"
          class="mb-4 bg-colorBlue"
          style="border-radius: 20px"
        >
          <div
            v-if="index % 2 === 0"
            class="d-flex align-items-center justify-content-center"
          >
            <div class="w-50 p-1">
              <p
                v-html="hospital.description"
                class="font-weight-normal text-colorText my-2"
                style="word-wrap: break-word; color: #ffffff !important"
              ></p>
              <p
                class="font-weight-bolder text-colorGreen cursor-pointer"
                @click="openHospital(hospital)"
              >
                Read More ......
              </p>
            </div>
            <div class="w-50 text-center" style="border-radius: 20px">
              <b-img
                :src="hospital.image"
                class="rounded p-1"
                style="height: 360px; width: 100%; border-radius: 20px"
                alt="image"
              />
            </div>
          </div>
          <div
            v-if="index % 2 !== 0"
            class="d-flex align-items-start justify-content-center"
          >
            <div class="w-50 text-center">
              <b-img
                :src="hospital.image"
                class="img-fluid rounded p-1"
                style="height: 360px; width: 100%; border-radius: 20px"
                alt="image"
              />
            </div>
            <div class="w-50 p-1">
              <p
                v-html="hospital.description"
                class="font-weight-normal text-colorText my-2"
                style="word-wrap: break-word; color: #ffffff !important"
              ></p>
              <p
                class="font-weight-bolder text-colorGreen cursor-pointer"
                @click="openHospital(hospital)"
              >
                Read More ......
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
  },
  mixins: [util],

  data() {
    return {
      hospitals: [],
    };
  },
  computed: {
    ...mapState({}),
  },

  methods: {
    ...mapActions({
      getHospitalsPublic: "appData/getHospitalsPublic",
    }),

    async fetchHospital() {
      try {
        const res_hospital = await this.getHospitalsPublic();
        this.hospitals = res_hospital.data;
        console.log(this.hospitals);
      } catch (error) {
        console.error("Error fetching hospital:", error);
      }
    },
    openHospital(hospital) {
      this.$router.push({
        name: "Hospital",
        params: {
          hospitalName: hospital.name,
          id: hospital.id,
        },
      });
    },
  },

  async mounted() {
    this.currentPage = 1;
    await this.fetchHospital();
  },
};
</script>

<style scoped>
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: calc(1.5em * 7);
}
</style>
