<template>
  <div>
    <Topbar />
    <Navbar />
    <Banner />
    <Body />
    <Footer />
    <CartButton />
  </div>
</template>

<script>
import Topbar from "@/components/home/Topbar.vue";
import Navbar from "@/components/home/Navbar.vue";
import Banner from "@/components/aboutUs/Banner.vue";
import Body from "@/components/aboutUs/Body.vue";
import Footer from "@/components/home/Footer.vue";
import CartButton from "@/components/floatingButton/CartButton.vue";

export default {
  metaInfo: {
    title: "About Us | MedAsk Tours",
    meta: [
      {
        name: "description",
        content:
          "MedAsk Tours is a premier platform dedicated to simplifying medical tourism. Learn about our mission to connect patients with world-class healthcare providers, offering accessible and affordable treatments worldwide. Discover our commitment to excellence and personalized care.",
      },
      {
        name: "keywords",
        content: "about us, company, team",
      },
    ],
  },
  components: {
    Topbar,
    Navbar,
    Banner,
    Body,
    Footer,
    CartButton,
  },

  data() {
    return {};
  },
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
};
</script>

<style></style>
