import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/home/Home.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/aboutUs",
      name: "AboutUs",
      component: () => import("@/views/aboutUs/AboutUs.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/contactUs",
      name: "ContactUs",
      component: () => import("@/views/contactUs/ContactUs.vue"),
      meta: {
        layout: "full",
        title: "Contact Us | MedAsk Tours",
        description:
          "Get in touch with MedAsk Tours for any inquiries or support regarding our medical tourism services. Our dedicated team is here to assist you with your healthcare needs and guide you through the medical travel process.",
        keywords:
          "contact MedAsk Tours, medical tourism support, healthcare inquiries, global healthcare assistance, patient services, contact us, medical travel information, MedAsk customer support, assistance with healthcare, get in touch MedAsk, contact page.",
        og: {
          title: "Contact Us | MedAsk Tours",
          description:
            "Get in touch with MedAsk Tours for any inquiries or support regarding our medical tourism services. Our dedicated team is here to assist you with your healthcare needs and guide you through the medical travel process.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/carRental",
      name: "CarRental",
      component: () => import("@/views/rental/Rental.vue"),
      meta: {
        layout: "full",
        title: "Car Rental Services Offered with Great Discounts",
        description:
          "Enjoy reliable and convenient transportation with MedAsk Tours' car rental services. Whether for airport transfers or local travel, we provide affordable and efficient car rental solutions.",
        keywords:
          "car rental, transportation services, medical travel cars, airport transfers, affordable car rental, reliable transportation, MedAsk Tours cars, travel convenience, chauffeur services, rental car solutions, seamless travel",
        og: {
          title: "Car Rental Services Offered with Great Discounts",
          description:
            "Enjoy reliable and convenient transportation with MedAsk Tours' car rental services. Whether for airport transfers or local travel, we provide affordable and efficient car rental solutions.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/interpreter",
      name: "Interpreter",
      component: () => import("@/views/interpreter/Interpreter.vue"),
      meta: {
        layout: "full",
        title: "Interpreter Services for Real-Time Communication",
        description:
          "MedAsk Tours eliminates language barriers with professional interpreter services, ensuring clear and effective communication between you and healthcare providers. You can experience a seamless and stress-free journey to better health.",
        keywords:
          "interpreter services, language assistance, medical interpreter, multilingual support, healthcare communication, professional interpreters, MedAsk Tours interpreters, language solutions, medical tourism interpreters, seamless communication",
        og: {
          title: "Interpreter Services for Real-Time Communication",
          description:
            "MedAsk Tours eliminates language barriers with professional interpreter services, ensuring clear and effective communication between you and healthcare providers. You can experience a seamless and stress-free journey to better health.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/hotel",
      name: "Hotel",
      component: () => import("@/views/hotel/Hotel.vue"),
      meta: {
        layout: "full",
        title: "Hotel Booking Services Offered with Great Discounts",
        description:
          "Find the best accommodations at hotels, motels. We offer hotel booking services tailored to your preferences and budget, ensuring a comfortable stay during your medical or leisure trip.",
        keywords:
          "hotel booking, accommodations, medical tourism hotels, affordable hotels, luxury stays, patient accommodation, travel lodging, MedAsk Tours hotels, comfortable stays, hotel reservations, trusted hotel options",
        og: {
          title: "Hotel Booking Services Offered with Great Discounts",
          description:
            "Find the best accommodations at hotels, motels. We offer hotel booking services tailored to your preferences and budget, ensuring a comfortable stay during your medical or leisure trip.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/safeTravel",
      name: "SafeTravel",
      component: () => import("@/views/travel/Travel.vue"),
      meta: {
        layout: "full",
        title: "Travel and Health Insurance | MedAsk Tours",
        description:
          "Stay protected with MedAsk Tours' comprehensive travel and health insurance services. We offer tailored insurance solutions to ensure your safety and peace of mind throughout your medical journey, covering all aspects of your trip.",
        keywords:
          "travel insurance, health insurance, medical travel protection, international healthcare insurance, insurance solutions, MedAsk Tours insurance, patient safety, comprehensive coverage, affordable insurance plans, medical tourism insurance",
        og: {
          title: "Travel and Health Insurance | MedAsk Tours",
          description:
            "Stay protected with MedAsk Tours' comprehensive travel and health insurance services. We offer tailored insurance solutions to ensure your safety and peace of mind throughout your medical journey, covering all aspects of your trip.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/tourism",
      name: "Tourism",
      component: () => import("@/views/tourism/Tourism.vue"),
      meta: {
        layout: "full",
        title: "Tourism Services | MedAsk Tours",
        description:
          "With MedAsk Tours, you can focus on your health while exploring local attractions. We offer personalized travel experiences that seamlessly combine medical care with unforgettable leisure activities, ensuring a well-rounded and enriching journey.",
        keywords:
          "tourism services, medical tourism, sightseeing, local attractions, leisure travel, MedAsk Tours tourism, cultural exploration, personalized travel plans, healthcare tourism, travel and care",
        og: {
          title: "Tourism Services | MedAsk Tours",
          description:
            "With MedAsk Tours, you can focus on your health while exploring local attractions. We offer personalized travel experiences that seamlessly combine medical care with unforgettable leisure activities, ensuring a well-rounded and enriching journey.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/insurance",
      name: "Insurance",
      component: () => import("@/views/insurance/Insurance.vue"),
      meta: {
        layout: "full",
        title: "Travel and Health Insurance | MedAsk Tours",
        description:
          "Stay protected with MedAsk Tours' comprehensive travel and health insurance services. We offer tailored insurance solutions to ensure your safety and peace of mind throughout your medical journey, covering all aspects of your trip.",
        keywords:
          "travel insurance, health insurance, medical travel protection, international healthcare insurance, insurance solutions, MedAsk Tours insurance, patient safety, comprehensive coverage, affordable insurance plans, medical tourism insurance",
        og: {
          title: "Travel and Health Insurance | MedAsk Tours",
          description:
            "Stay protected with MedAsk Tours' comprehensive travel and health insurance services. We offer tailored insurance solutions to ensure your safety and peace of mind throughout your medical journey, covering all aspects of your trip.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/hospital/:hospitalName/:id",
      name: "Hospital",
      component: () => import("@/views/hospital/Hospital.vue"),
      meta: {
        layout: "full",
        title:
          "Leading hospitals and institutes for the medical tourism services in Pakistan",
        description:
          "MedAsk providing premier healthcare facilities, dedicated to providing world-class medical services.",
        keywords:
          "hospital services, healthcare solutions, medical professionals, world-class treatment, patient care, medical tourism, affordable treatments, hospital information, quality healthcare, advanced medical care, trusted healthcare provider, seamless patient experience, MedAsk Tours hospital page, Medical Institutes, haspatal",
        og: {
          title:
            "Leading hospitals and institutes for the medical tourism services in Pakistan",
          description:
            "MedAsk providing premier healthcare facilities, dedicated to providing world-class medical services.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/hospitals",
      name: "HospitalPage",
      component: () => import("@/views/hospital/HospitalPage.vue"),
      meta: {
        layout: "full",
        title:
          "Leading hospitals and institutes for the medical tourism services in Pakistan",
        description:
          "MedAsk providing premier healthcare facilities, dedicated to providing world-class medical services.",
        keywords:
          "hospital services, healthcare solutions, medical professionals, world-class treatment, patient care, medical tourism, affordable treatments, hospital information, quality healthcare, advanced medical care, trusted healthcare provider, seamless patient experience, MedAsk Tours hospital page, Medical Institutes, haspatal",
        og: {
          title:
            "Leading hospitals and institutes for the medical tourism services in Pakistan",
          description:
            "MedAsk providing premier healthcare facilities, dedicated to providing world-class medical services.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },

    {
      path: "/treatment/:treatmentName/:id",
      name: "Treatment",
      component: () => import("@/views/treatment/Treatment.vue"),
      meta: {
        layout: "full",
        title: "Treatments | Medask Tours",
        description:
          "MedAsk providing premier healthcare facilities, dedicated to providing world-class medical services.",
        keywords:
          "hospital services, healthcare solutions, medical professionals, world-class treatment, patient care, medical tourism, affordable treatments, hospital information, quality healthcare, advanced medical care, trusted healthcare provider, seamless patient experience, MedAsk Tours hospital page, Medical Institutes, haspatal",
        og: {
          title: "Treatments | Medask Tours",
          description:
            "MedAsk providing premier healthcare facilities, dedicated to providing world-class medical services.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/treatments",
      name: "TreatmentPage",
      component: () => import("@/views/treatment/TreatmentPage.vue"),
      meta: {
        layout: "full",
        title: "Treatments | Medask Tours",
        description:
          "MedAsk providing premier healthcare facilities, dedicated to providing world-class medical services.",
        keywords:
          "hospital services, healthcare solutions, medical professionals, world-class treatment, patient care, medical tourism, affordable treatments, hospital information, quality healthcare, advanced medical care, trusted healthcare provider, seamless patient experience, MedAsk Tours hospital page, Medical Institutes, haspatal",
        og: {
          title: "Treatments | Medask Tours",
          description:
            "MedAsk providing premier healthcare facilities, dedicated to providing world-class medical services.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/termsOfServices",
      name: "TermsOfServices",
      component: () => import("@/views/support/TermsOfServices.vue"),
      meta: {
        layout: "full",
        title: "MedAsk Tours - Terms of Services",
        description:
          "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
        keywords:
          "Medical Tourism, Health Tourism, Medical Travel, Healthcare Travel, Overseas Medical Treatment, Affordable Healthcare, Medical Tourism in Pakistan, International Patients, Best Healthcare in Pakistan, Skilled Doctors, Cosmetic Surgery, Dental Tourism, Fertility Treatment, Cardiac Surgery, Cancer Treatment, Plastic Surgery, Orthopedic Surgery, Eye Surgery, Weight Loss Surgery, Medical Packages, Top Hospitals in Pakistan, Global Healthcare Solutions",
        og: {
          title: "MedAsk Tours - Terms of Services",
          description:
            "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/privacyPolicy",
      name: "PrivacyPolicy",
      component: () => import("@/views/support/PrivacyPolicy.vue"),
      meta: {
        layout: "full",
        title: "MedAsk Tours - Privacy Policy",
        description:
          "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
        keywords:
          "Medical Tourism, Health Tourism, Medical Travel, Healthcare Travel, Overseas Medical Treatment, Affordable Healthcare, Medical Tourism in Pakistan, International Patients, Best Healthcare in Pakistan, Skilled Doctors, Cosmetic Surgery, Dental Tourism, Fertility Treatment, Cardiac Surgery, Cancer Treatment, Plastic Surgery, Orthopedic Surgery, Eye Surgery, Weight Loss Surgery, Medical Packages, Top Hospitals in Pakistan, Global Healthcare Solutions",
        og: {
          title: "MedAsk Tours - Privacy Policy",
          description:
            "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/packages",
      name: "Packages",
      component: () => import("@/views/packages/Packages.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/overseasPakistanis",
      name: "OverseasPakistani",
      component: () => import("@/views/overseas/Overseas.vue"),
      meta: {
        layout: "full",
        title: "Healthcare and Support for Overseas Pakistanis",
        description:
          "MedAsk Tours is here to support Overseas Pakistanis facing challenges in accessing quality healthcare. We bridge the gap by offering seamless access to top medical facilities in Pakistan, For those working abroad and struggling with healthcare, we ensure a stress-free, efficient, and reliable solution that brings the care you deserve closer to home.",
        keywords:
          "overseas Pakistanis, healthcare for Pakistanis abroad, medical tourism for Pakistanis, visa assistance for overseas Pakistanis, Pakistan healthcare services, medical travel solutions, MedAsk Tours for Pakistanis, support for overseas Pakistanis, international healthcare access, quality care in Pakistan.",
        og: {
          title: "Healthcare and Support for Overseas Pakistanis",
          description:
            "MedAsk Tours provides comprehensive healthcare and support services for overseas Pakistanis, offering seamless access to top medical care in Pakistan. From medical treatments to visa assistance, accommodations, and interpreter services, we ensure a smooth and stress-free journey for Pakistanis seeking quality healthcare back home.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    // {
    //   path: "/cart",
    //   name: "Cart",
    //   component: () => import("@/views/cart/Cart.vue"),
    //   meta: {
    //     layout: "full",
    //   },
    // },
    {
      path: "/visaAssistance",
      name: "VisaAssistance",
      component: () => import("@/views/visaAssistance/VisaAssistance.vue"),
      meta: {
        layout: "full",
        title: "Visa Assistance for Foreign Patients",
        description:
          "Simplify a quick visa assistance by team MedAsk Tours and assistance services.",
        keywords:
          "visa assistance, visa services, medical travel visa, international healthcare visa, hassle-free visa process, visa support, MedAsk Tours visa, travel documentation, visa facilitation, medical tourism visa solutions.",
        og: {
          title: "Visa Assistance for Foreign Patients",
          description:
            "Simplify a quick visa assistance by team MedAsk Tours and assistance services.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    // {
    //   path: "/signUp",
    //   name: "SignUp",
    //   component: () => import("@/views/auth/SignUp.vue"),
    //   meta: {
    //     layout: "full",
    //   },
    // },
    {
      path: "/searchView",
      name: "SearchView",
      component: () => import("@/views/searchView/Search.vue"),
      meta: {
        layout: "full",
        title: "MedAsk Tours - Pakistan's Leading Medical Tourism Provider",
        description:
          "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
        keywords:
          "Medical Tourism, Health Tourism, Medical Travel, Healthcare Travel, Overseas Medical Treatment, Affordable Healthcare, Medical Tourism in Pakistan, International Patients, Best Healthcare in Pakistan, Skilled Doctors, Cosmetic Surgery, Dental Tourism, Fertility Treatment, Cardiac Surgery, Cancer Treatment, Plastic Surgery, Orthopedic Surgery, Eye Surgery, Weight Loss Surgery, Medical Packages, Top Hospitals in Pakistan, Global Healthcare Solutions",
        og: {
          title: "MedAsk Tours - Pakistan's Leading Medical Tourism Provider",
          description:
            "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/doctors",
      name: "Doctors",
      component: () => import("@/views/doctor/AllDoctor.vue"),
      meta: {
        layout: "full",
        title: "Top medical tourism doctors in Pakistan, Find Top Doctors",
        description:
          "Doctors for Orthopaedics, Dentistry, Oncology, dental surgeon, Plastic Surgery, Dermatology, Cardiology, Spine Surgery, Urology at MedAsk Tours",
        keywords:
          "doctor for Urology, Urology doctor, Dr. Shahid Iqbal Khan, Dr. Muhammad Ishaq, Dr. Adnan Babar, Dr. Rana Hassan Javaid, Dr. Khurram Akhtar, Dr. Amjad Mahmood, Dr. Imtiaz Ahmed",
        og: {
          title: "Top medical tourism doctors in Pakistan, Find Top Doctors",
          description:
            "Doctors for Orthopaedics, Dentistry, Oncology, dental surgeon, Plastic Surgery, Dermatology, Cardiology, Spine Surgery, Urology at MedAsk Tours",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/blog",
      name: "Blog",
      component: () => import("@/views/blog/SingleBlog.vue"),
      meta: {
        layout: "full",
        title: "MedAsk Tours - Blogs",
        description:
          "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
        keywords:
          "Medical Tourism, Health Tourism, Medical Travel, Healthcare Travel, Overseas Medical Treatment, Affordable Healthcare, Medical Tourism in Pakistan, International Patients, Best Healthcare in Pakistan, Skilled Doctors, Cosmetic Surgery, Dental Tourism, Fertility Treatment, Cardiac Surgery, Cancer Treatment, Plastic Surgery, Orthopedic Surgery, Eye Surgery, Weight Loss Surgery, Medical Packages, Top Hospitals in Pakistan, Global Healthcare Solutions",
        og: {
          title: "MedAsk Tours - Blogs",
          description:
            "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/allBlog",
      name: "AllBlog",
      component: () => import("@/views/blog/AllBlog.vue"),
      meta: {
        layout: "full",
        title: "MedAsk Tours - Blogs",
        description:
          "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
        keywords:
          "Medical Tourism, Health Tourism, Medical Travel, Healthcare Travel, Overseas Medical Treatment, Affordable Healthcare, Medical Tourism in Pakistan, International Patients, Best Healthcare in Pakistan, Skilled Doctors, Cosmetic Surgery, Dental Tourism, Fertility Treatment, Cardiac Surgery, Cancer Treatment, Plastic Surgery, Orthopedic Surgery, Eye Surgery, Weight Loss Surgery, Medical Packages, Top Hospitals in Pakistan, Global Healthcare Solutions",
        og: {
          title: "MedAsk Tours - Blogs",
          description:
            "MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties.",
          image: "@/assets/images/logo/medask.png",
          url: window.location.href,
        },
      },
    },
    {
      path: "/user",
      name: "User",
      component: () => import("@/views/user/User.vue"),
      meta: {
        pageTitle: "User",
        breadcrumb: [
          {
            text: "User",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_user",
      },
    },
    {
      path: "/role",
      name: "Role",
      component: () => import("@/views/role/Role.vue"),
      meta: {
        pageTitle: "Role",
        breadcrumb: [
          {
            text: "Role",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_role",
      },
    },
    {
      path: "/add_department",
      name: "AddDepartment",
      component: () => import("@/views/admin/department/Department.vue"),
      meta: {
        pageTitle: "Department",
        breadcrumb: [
          {
            text: "Department",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_department",
      },
    },

    {
      path: "/add_doctor",
      name: "AddDoctor",
      component: () => import("@/views/admin/doctor/Doctor.vue"),
      meta: {
        pageTitle: "Doctor",
        breadcrumb: [
          {
            text: "Doctor",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_doctor",
      },
    },
    {
      path: "/doctor_dashboard",
      name: "DoctorDashboard",
      component: () => import("@/views/admin/doctor/DoctorDashboard.vue"),
      meta: {
        pageTitle: "Doctor Dashboard",
        breadcrumb: [
          {
            text: "Doctor Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_doctor_dashboard",
      },
    },

    {
      path: "/add_procedure",
      name: "AddProcedure",
      component: () => import("@/views/admin/procedure/Procedure.vue"),
      meta: {
        pageTitle: "Procedure",
        breadcrumb: [
          {
            text: "Procedure",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_procedure",
      },
    },

    {
      path: "/add_hospital",
      name: "AddHospital",
      component: () => import("@/views/admin/hospital/Hospital.vue"),
      meta: {
        pageTitle: "Hospital",
        breadcrumb: [
          {
            text: "Hospital",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_hospital",
      },
    },

    {
      path: "/add_hotel",
      name: "AddHotel",
      component: () => import("@/views/admin/hotel/Hotel.vue"),
      meta: {
        pageTitle: "Hotel",
        breadcrumb: [
          {
            text: "Hotel",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_hotel",
      },
    },

    {
      path: "/add_room",
      name: "AddRoom",
      component: () => import("@/views/admin/room/Room.vue"),
      meta: {
        pageTitle: "Room",
        breadcrumb: [
          {
            text: "Room",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_room_category",
      },
    },

    {
      path: "/add_rental_company",
      name: "AddRentalCompany",
      component: () => import("@/views/admin/rentalCompany/RentalCompany.vue"),
      meta: {
        pageTitle: "Rental Company",
        breadcrumb: [
          {
            text: "Rental Company",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_rental_company",
      },
    },

    {
      path: "/add_rental_car",
      name: "AddRentalCar",
      component: () => import("@/views/admin/rentalCar/RentalCar.vue"),
      meta: {
        pageTitle: "Rental Car",
        breadcrumb: [
          {
            text: "Rental Car",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_rental_car",
      },
    },

    {
      path: "/add_security",
      name: "AddSecurity",
      component: () => import("@/views/admin/security/Security.vue"),
      meta: {
        pageTitle: "Security Services",
        breadcrumb: [
          {
            text: "Security Services",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_security_package",
      },
    },

    {
      path: "/add_interprator_company",
      name: "AddInterpratorCompany",
      component: () =>
        import("@/views/admin/interpratorCompany/InterpratorCompany.vue"),
      meta: {
        pageTitle: "Interprator Company",
        breadcrumb: [
          {
            text: "Interprator Company",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_interprator_company",
      },
    },

    {
      path: "/add_interprator_serice",
      name: "AddInterpratorService",
      component: () =>
        import("@/views/admin/interpratorService/InterpratorService.vue"),
      meta: {
        pageTitle: "Interprator Service",
        breadcrumb: [
          {
            text: "Interprator Service",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_interprator_service",
      },
    },

    {
      path: "/tourist_attraction",
      name: "AddTourist",
      component: () =>
        import("@/views/admin/tourist_attraction/TouristAttraction.vue"),
      meta: {
        pageTitle: "Tourist Attraction",
        breadcrumb: [
          {
            text: "Tourist Attraction",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_tourist_attraction",
      },
    },
    {
      path: "/add_tourist_trip",
      name: "AddTouristTrip",
      component: () => import("@/views/admin/tourist_trip/TouristTrip.vue"),
      meta: {
        pageTitle: "Tourist Trip",
        breadcrumb: [
          {
            text: "Tourist Trip",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_tourist_trip",
      },
    },

    {
      path: "/patient_dashboard",
      name: "PatientDashboard",
      component: () => import("@/views/admin/patient/Dashboard.vue"),
      meta: {
        pageTitle: "Patient Dashboard",
        breadcrumb: [
          {
            text: "Patient Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_patient_dashboard",
      },
    },

    {
      path: "/payment_successful",
      name: "PaymentSuccessful",
      component: () =>
        import("@/views/admin/patient/payment_pages/PaymentSuccessful.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
        permission: "show_patient_dashboard",
      },
    },

    {
      path: "/payment_unsuccessful",
      name: "PaymentUnsuccessful",
      component: () =>
        import("@/views/admin/patient/payment_pages/PaymentUnsuccessful.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
        permission: "show_patient_dashboard",
      },
    },

    {
      path: "/patient",
      name: "Patient",
      component: () => import("@/views/admin/csr/Patient.vue"),
      meta: {
        pageTitle: "Patient",
        breadcrumb: [
          {
            text: "Patient",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "read_patient",
      },
    },

    {
      path: "/create_order",
      name: "CreateOrder",
      component: () => import("@/views/admin/csr/CreateOrder.vue"),
      meta: {
        pageTitle: "CSR Panel",
        breadcrumb: [
          {
            text: "Create Order",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_create_order",
      },
    },

    {
      path: "/bookings",
      name: "ShowBooking",
      component: () => import("@/views/admin/csr/Booking.vue"),
      meta: {
        pageTitle: "Bookings",
        breadcrumb: [
          {
            text: "Bookings",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_create_order",
      },
    },

    {
      path: "/visa_assistance",
      name: "AddVisaAssistance",
      component: () =>
        import("@/views/admin/visaAssistance/VisaAssistance.vue"),
      meta: {
        pageTitle: "Visa Assistance",
        breadcrumb: [
          {
            text: "Visa Assistance",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_visa_assistance",
      },
    },

    {
      path: "/insurance",
      name: "AddInsurance",
      component: () => import("@/views/admin/insurance/Insurance.vue"),
      meta: {
        pageTitle: "Insurance",
        breadcrumb: [
          {
            text: "Insurance",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_insurance",
      },
    },

    {
      path: "/blog_category",
      name: "AddBlogCategory",
      component: () => import("@/views/admin/blog_category/BlogCategory.vue"),
      meta: {
        pageTitle: "Blog Category",
        breadcrumb: [
          {
            text: "Blog Category",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_blog_category",
      },
    },

    {
      path: "/blog",
      name: "AddBlog",
      component: () => import("@/views/admin/blog/Blog.vue"),
      meta: {
        pageTitle: "Blog",
        breadcrumb: [
          {
            text: "Blog",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_blog",
      },
    },

    {
      path: "/user_profile",
      name: "UserProfile",
      component: () => import("@/views/userprofile/Userprofile.vue"),
      meta: {
        pageTitle: "User Profile",
        breadcrumb: [
          {
            text: "User Profile",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "update_user",
      },
    },

    {
      path: "/change_password",
      name: "ChangePassword",
      component: () => import("@/views/userprofile/Changepassword.vue"),
      meta: {
        pageTitle: "Change Password",
        breadcrumb: [
          {
            text: "Change Password",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "update_user",
      },
    },

    {
      path: "/consultations",
      name: "ShowConsultations",
      component: () => import("@/views/admin/csr/Consultation.vue"),
      meta: {
        pageTitle: "Consultations",
        breadcrumb: [
          {
            text: "Consultations",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_create_order",
      },
    },
    {
      path: "/add_gallery",
      name: "AddGallery",
      component: () => import("@/views/admin/gallery/Gallery.vue"),
      meta: {
        pageTitle: "Gallery",
        breadcrumb: [
          {
            text: "Gallery",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_gallery",
      },
    },
    {
      path: "/faq",
      name: "AddFAQ",
      component: () => import("@/views/admin/faq/FAQ.vue"),
      meta: {
        pageTitle: "FAQ",
        breadcrumb: [
          {
            text: "FAQ",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_faq",
      },
    },

    {
      path: "/quote",
      name: "Quote",
      component: () => import("@/views/admin/quote/Quote.vue"),
      meta: {
        pageTitle: "Quote",
        breadcrumb: [
          {
            text: "Quote",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_quote",
      },
    },
    {
      path: "/overseasQuote",
      name: "OverseasQuote",
      component: () => import("@/views/admin/overseasQuote/OverseasQuote.vue"),
      meta: {
        pageTitle: "Overseas Quote",
        breadcrumb: [
          {
            text: "Overseas Quote",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_quote",
      },
    },
    {
      path: "/referralCompany",
      name: "ReferralCompany",
      component: () =>
        import("@/views/admin/referralCompany/ReferralCompany.vue"),
      meta: {
        pageTitle: "ReferralCompany",
        breadcrumb: [
          {
            text: "ReferralCompany",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_company",
      },
    },
    {
      path: "/healthPackages",
      name: "HealthPackages",
      component: () => import("@/views/admin/packages/Packages.vue"),
      meta: {
        pageTitle: "Health Packages",
        breadcrumb: [
          {
            text: "Health Packages",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_health_package",
      },
    },

    {
      path: "/testimonial",
      name: "AddTestimonial",
      component: () => import("@/views/admin/testimonial/Testimonial.vue"),
      meta: {
        pageTitle: "Testimonial",
        breadcrumb: [
          {
            text: "Testimonial",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_testimonial",
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (from.name) {
    localStorage.setItem("lastRoute", from.name);
  }
  next();
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
