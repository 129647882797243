<template>
  <b-modal
    id="doctor-edit-modal"
    title="Doctor Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Doctor Edit</h2>
    </template>
    <validation-observer ref="doctorEditFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="name">
          <template #label> Name <span class="text-danger">*</span> </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Doctor Name"
            required
            @keydown="checkMaxLength($event, 'name', 200)"
          />
        </b-form-group>

        <b-form-group label-for="email">
          <template #label> Email <span class="text-danger">*</span> </template>
          <b-form-input
            id="email"
            type="email"
            v-model="email"
            placeholder="Email"
            required
            @keydown="checkMaxLength($event, 'email', 100)"
          />
        </b-form-group>

        <b-form-group label-for="mobile">
          <template #label>
            Mobile <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mobile"
            rules="required|integer|length:11"
          >
            <b-form-input
              id="mobile"
              v-model="mobile"
              type="text"
              :state="errors.length > 0 ? false : null"
              placeholder="Mobile"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="password">
          <template #label>
            Password <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Password"
            :rules="{
              regex:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[\S]{8,}$/,
            }"
          >
            <b-form-input
              id="password"
              type="password"
              v-model="password"
              placeholder="Password"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <span :class="noteClass(errors)" style="font-size: 12px">
              {{ noteText(errors) }}
            </span>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="intro">
          <template #label> Intro <span class="text-danger">*</span> </template>
          <b-form-textarea
            id="intro"
            v-model="intro"
            placeholder="Intro"
            rows="3"
            max-rows="6"
            @keydown="checkMaxLength($event, 'intro', 400)"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-for="about">
          <template #label> Credentials </template>
          <quill-editor id="about" v-model="about" />
        </b-form-group>

        <b-form-group label-for="activities">
          <template #label> Bio </template>
          <quill-editor id="activities" v-model="activities" />
        </b-form-group>

        <b-form-group label-for="hospital">
          <template #label>
            Hospital <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Hospital"
            rules="required"
          >
            <v-select
              label="name"
              v-model="hospital"
              placeholder="Select Hospital"
              :options="hospitals"
              :reduce="(option) => option.id"
              autoscroll
              :clearable="true"
            ></v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="department">
          <template #label>
            Department <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Department"
            rules="required"
          >
            <v-select
              label="name"
              v-model="department"
              placeholder="Select Department"
              :options="departments"
              :reduce="(option) => option.id"
              autoscroll
              :clearable="true"
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="tags">
          <template #label> Doctor Tags </template>
          <b-form-input
            id="tags"
            v-model="tags"
            placeholder="Tags"
            required
            key="tags"
          />
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label-for="image">
          <template #label> Image <span class="text-danger">*</span> </template>
          <div v-if="image" class="mb-2">
            <img
              :src="image"
              alt="Doctor Image"
              class="img-thumbnail"
              style="max-width: 15%; margin-bottom: 10px"
            />
          </div>
          <validation-provider v-slot="{ errors }" name="Image">
            <b-form-group
              class="form-group"
              id="input-group-2"
              label-for="input-2"
              description="Upload an image in PNG, JPG, Webp or JPEG format."
              :invalid-feedback="errors[0]"
            >
              <b-form-file
                ref="imageInput"
                id="documents"
                placeholder="Choose a PNG, JPG, Webp or JPEG file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".png,.jpg,.jpeg,.webp"
                plain
                v-model="files"
                @change="validateFileType($event, 'files')"
              ></b-form-file>
            </b-form-group>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="info" pill class="mr-1">
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  props: {
    doctor: Object,
  },
  data() {
    return {
      required,
      email: "",
      cnic: "",
      mobile: "",
      password: "",
      name: "",
      intro: "",
      about: "",
      activities: "",
      files: null,
      image: null,
      hospital: null,
      hospitals: [],
      department: null,
      departments: [],
      status: "",
      tags: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    const res_hospitals = await this.getHospitalsPublic();
    this.hospitals = res_hospitals.data;

    const res_departments = await this.getDepartmentsPublic();
    this.departments = res_departments.data;

    if (this.doctor) {
      this.email = this.doctor.email;
      this.cnic = this.doctor.cnic;
      this.mobile = this.doctor.mobile;
      this.password = "";
      this.name = this.doctor.name;
      this.intro = this.doctor.intro;
      this.about = this.doctor.about;
      this.activities = this.doctor.activities;
      this.hospital = this.doctor.hospital;
      this.department = this.doctor.department;
      this.status = this.doctor.status;
      this.tags = this.doctor.tags;
      this.image = this.doctor.profile_image;
    }
  },
  methods: {
    ...mapActions({
      updateDoctor: "appData/updateDoctor",
      getHospitalsPublic: "appData/getHospitalsPublic",
      getDepartmentsPublic: "appData/getDepartmentsPublicNav",
    }),
    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
      if (!allowedTypes.includes(file.type)) {
        event.target.value = null;
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity(
            "Please upload a PNG, JPG, Webp or JPEG image file."
          );
          this.$refs.imageInput.$el.reportValidity();
        }
      } else {
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity("");
        }
      }
    },
    noteClass(errors) {
      return errors.length === 0 && this.password.length > 0
        ? "font-weight-normal text-success"
        : "font-weight-normal text-danger";
    },
    noteText(errors) {
      if (this.password.length === 0) {
        return "";
      }
      return errors.length === 0
        ? "Perfect Passowrd."
        : "The password must be at least 8 characters long, at least one lowercase letter, at least one uppercase letter, at least one digit and at least one special character (e.g., @$!%*?&).";
    },
    async validationForm() {
      const success = await this.$refs.doctorEditFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },

    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async submit() {
      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("cnic", this.cnic);
      formData.append("mobile", this.mobile);
      if (this.password) {
        formData.append("password", this.password);
      }
      formData.append("full_name", this.name);
      formData.append("intro", this.intro);
      formData.append("about", this.about);
      formData.append("activities", this.activities);
      formData.append("hospital", this.hospital);
      formData.append("department", this.department);
      formData.append("tags", this.tags);
      formData.append("status", this.status);
      if (this.files) {
        formData.append("profile_image", this.files);
      }
      formData.append("updated_by", this.getLoggedInUser.id);

      try {
        const res = await this.updateDoctor({
          payload: formData,
          pk: this.doctor.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Doctor updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("doctor-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.email = "";
      this.cnic = "";
      this.mobile = "";
      this.password = "";
      this.name = "";
      this.intro = "";
      this.about = "";
      this.activities = "";
      this.files = null;
      this.hospital = null;
      this.department = null;
      this.status = "";
      this.tags = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
