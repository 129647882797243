<template>
  <div>
    <Topbar />
    <Navbar class="sticky-top" />
    <Banner />
    <Hospital />
    <Doctor />

    <Process />
    <GetQuote />
    <Testimonial />
    <Newsletter />
    <FAQ />

    <!-- <Tourism /> -->
    <!-- <Pricing /> -->
    <!-- <Review /> -->
    <!-- <Blogs /> -->

    <Footer />
    <CartButton />
    <!-- <SocialChat icon :attendants="attendants">
      <p slot="header" class="font-weight-bolder">
        Click on one of our attendants below to chat on WhatsApp.
      </p>
      <template v-slot:button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        />
      </template>
      <small class="font-weight-bolder" slot="footer">Open: 24 hrs </small>
    </SocialChat> -->
  </div>
</template>

<script>
import Topbar from "@/components/home/Topbar.vue";
import Navbar from "@/components/home/Navbar.vue";
import Banner from "@/components/home/Banner.vue";
import Hospital from "@/components/home/Hospital.vue";
import Doctor from "@/components/home/Doctor.vue";
import Tourism from "@/components/home/Tourism.vue";
import Pricing from "@/components/home/Pricing.vue";
import Review from "@/components/home/Review.vue";
import Blogs from "@/components/home/Blogs.vue";
import Footer from "@/components/home/Footer.vue";
import Testimonial from "@/components/home/Testimonial.vue";
import Newsletter from "@/components/home/Newsletter.vue";
import GetQuote from "@/components/home/GetQuote.vue";
import FAQ from "@/components/home/FAQ.vue";
import Process from "@/components/home/Process.vue";
import CartButton from "@/components/floatingButton/CartButton.vue";
import { SocialChat } from "vue-social-chat";
    import { mapGetters } from "vuex";

  import Vue from 'vue'
  import VueConfetti from 'vue-confetti'

    Vue.use(VueConfetti)

export default {
  components: {
    SocialChat,
    CartButton,
    Topbar,
    Navbar,
    Banner,
    Hospital,
    Doctor,
    Tourism,
    Pricing,
    Blogs,
    Review,
    Footer,
    Testimonial,
    Newsletter,
    FAQ,
    GetQuote,
    Process,
  },
  metaInfo() {
    return {
      title: "MedAsk Tours - Pakistan's Leading Medical Tourism Provider",
      meta: [
        { vmid: "description", name: "description", content: "We MedAsk Tours connects international patients to top hospitals in Pakistan, offering high-quality, affordable medical treatments across 28+ specialties." },
      ]
    };
  },
  data() {
    return {
      attendants: [
        {
          app: "whatsapp",
          label: "Medask Tours",
          name: "Customer Support",
          number: "923196048487",
          avatar: {
            src: "https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4",
            alt: "Alan Ktquez avatar",
          },
        },
      ],
           show: false,
                VueConfetti
    };
  },
          mounted() {
            this.show = true;
            if (this.inaguration == "fireinthehole") {
                this.start();
                this.function();
            }
        },
        methods:{
                     start() {
                this.$confetti.start();
                this.$confetti.update({
                    particles: [
                        {
                            type: 'heart',
                        },
                        {
                            type: 'rect',
                        },
                    ],
                    defaultDropRate: 10,
                });
            },
            stop() {
                this.$confetti.stop();
            },

        },
  computed: {
    ...mapGetters({
      inaguration: "appData/inaguration",
    }),
  },
};
</script>

<style>
:root {
  --vsc-bg-header: #091e3d;
  --vsc-bg-footer: #06bd46;
  --vsc-text-color-header: white;
  --vsc-text-color-footer: white;
  --vsc-bg-button: #06bd46;
  --vsc-text-color-button: white;
  --vsc-outline-color: #091e3d;
  --vsc-border-color-bottom-header: transparent;
  --vsc-border-color-top-footer: #f3f3f3;
}

.social-chat-header {
  background-color: var(--vsc-bg-header) !important;
  color: var(--vsc-text-color-header) !important;
}

.social-chat-footer {
  background-color: var(--vsc-bg-footer);
  color: var(--vsc-text-color-footer);
}

.social-chat-button {
  background-color: var(--vsc-bg-button);
  color: var(--vsc-text-color-button);
}

.social-chat-outline {
  outline-color: var(--vsc-outline-color);
}

.social-chat-header {
  border-bottom: 1px solid var(--vsc-border-color-bottom-header);
}

.social-chat-footer {
  border-top: 1px solid var(--vsc-border-color-top-footer);
}
 .bounce-enter-active {
        animation: bounce-in 0.5s;
    }

    .bounce-leave-active {
        animation: bounce-in 2s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }

        50% {
            transform: scale(1.25);
        }

        100% {
            transform: scale(1);
        }
    }

    .slide-fade-land-enter-active {
        animation: bounce-in 2s ease-out;
    }

    .slide-fade-land-leave-active {
        transition: all 2s ease-out;
    }

    .slide-fade-land-enter-from,
    .slide-fade-land-leave-to {
        transform: translate(-2.1rem, -2ex);
        opacity: 0;
    }
</style>
</style>
